var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"saveFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"id":"practicalResearchClassGroupSaveModal","centered":"","size":"lg","body-class":"position-static","title":"Cập nhật thông tin "},on:{"show":_vm.onShow,"hide":_vm.onHide},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-content-end"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.onSave}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Lưu và tiếp tục ")],1)]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.onSave('hide')}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Lưu lại ")],1)]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$bvModal.hide('practicalResearchClassGroupSaveModal')}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}}),_vm._v(" Hủy ")],1)])],1)]},proxy:true}],null,true)},[_c('b-form',[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tên nhóm "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tên nhóm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","placeholder":"Nhập tên nhóm","state":_vm.getElementState(errors)},model:{value:(_vm.targetItem.name),callback:function ($$v) {_vm.$set(_vm.targetItem, "name", $$v)},expression:"targetItem.name"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"practicalResearchProgramId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Chương trình nghiên cứu thực tế "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Chương trình nghiên cứu thực tế","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.practicalResearchPrograms,"reduce":function (option) { return option.value; }},model:{value:(_vm.targetItem.practicalResearchProgramId),callback:function ($$v) {_vm.$set(_vm.targetItem, "practicalResearchProgramId", $$v)},expression:"targetItem.practicalResearchProgramId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"teacherId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Giảng viên hướng dẫn"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Giảng viên hướng dẫn","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"multiple":"","options":_vm.teachers,"reduce":function (option) { return option.value; }},model:{value:(_vm.targetItem.teacherIds),callback:function ($$v) {_vm.$set(_vm.targetItem, "teacherIds", $$v)},expression:"targetItem.teacherIds"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"timeStart"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Thời gian bắt đầu "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Trưởng đoàn","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"locale":"vi","format":"dd/mm/yyyy","placeholder":"dd/mm/yyyy"},model:{value:(_vm.targetItem.timeStart),callback:function ($$v) {_vm.$set(_vm.targetItem, "timeStart", $$v)},expression:"targetItem.timeStart"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }