<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="practicalResearchClassGroupSaveModal"
      centered
      size="lg"
      body-class="position-static"
      title="Cập nhật thông tin "
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col>
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên nhóm <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên nhóm"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetItem.name"
                  name="name"
                  placeholder="Nhập tên nhóm"
                  :state="getElementState(errors)"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="practicalResearchProgramId">
              <template v-slot:label>
                Chương trình nghiên cứu thực tế <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Chương trình nghiên cứu thực tế"
                rules="required"
              >
                <v-select
                  v-model="targetItem.practicalResearchProgramId"
                  :options="practicalResearchPrograms"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="teacherId">
              <template v-slot:label>
                Giảng viên hướng dẫn<span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Giảng viên hướng dẫn"
                rules="required"
              >
                <v-select
                  v-model="targetItem.teacherIds"
                  multiple
                  :options="teachers"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label-for="timeStart"
            >
              <template v-slot:label>
                Thời gian bắt đầu <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trưởng đoàn"
                rules="required"
              >
                <b-form-datepicker
                  v-model="targetItem.timeStart"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  locale="vi"
                  format="dd/mm/yyyy"
                  placeholder="dd/mm/yyyy"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('practicalResearchClassGroupSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>
<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BModal,
  BOverlay,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BCol,
  BRow,
  BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'

export default {
  name: 'PracticalResearchClassGroupSave',
  directives: {
    Ripple,
  },
  components: {
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BCol,
    BRow,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,

  },
  props: {
    currentGroup: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        id: null,
        address: '',
        name: '',
        classId: null,
        teacher: '',
        teacherIds: [],
        departmentId: null,
        practicalResearchProgramId: null,
        timeStudy: null,
        timeStart: null,
        timeEnd: null,
        status: 1,
      },
      departmentId: null,
      required,
    }
  },
  computed: {
    ...mapGetters({
      departments: 'dropdown/departments',
      teachers: 'dropdown/teachers',
      practicalResearchPrograms: 'dropdown/practicalResearchPrograms',
    }),
  },
  methods: {
    ...mapActions({
      getDepartments: 'dropdown/getDepartments',
      getTeachers: 'dropdown/getTeachers',
      getPracticalResearchPrograms: 'dropdown/getPracticalResearchPrograms',
      updatePracticalResearchClassGroup: 'practicalResearchClassGroup/updatePracticalResearchClassGroup',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if (this.currentGroup) {
        this.targetItem = { ...this.currentGroup }
      }
      if (this.targetItem.teacherIds !== null) {
        this.targetItem.teacherIds = this.targetItem.teacherIds.split(',').map(Number)
      }
      this.isLoading = true
      try {
        const cUser = getUser()
        const userOrgId = (cUser.orgId ? cUser.orgId : 0)
        this.departmentId = null
        await Promise.all([
          await this.getPracticalResearchPrograms({
            currentPage: 1,
            itemsPerPage: 1000,
          }),
          await this.getDepartments({ organizationId: userOrgId }),
          await this.getTeachers({}),
        ])
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.targetItem = {
        id: null,
        name: '',
        classId: null,
        teacherIds: [],
        practicalResearchProgramId: null,
        timeStart: null,
        timeEnd: null,
        status: 1,
      }
    },
    async onSave(type = null) {
      this.isLoading = true
      try {
        this.targetItem.status = 1
        const response = await this.updatePracticalResearchClassGroup(this.targetItem)
        if (response) {
          const {
            isSuccessful,
            message,
          } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (type === 'hide') {
              this.$bvModal.hide('practicalResearchClassGroupSaveModal')
            }
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">

</style>
