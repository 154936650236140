<template>
  <div class="row">
    <b-container fluid>
      <b-card>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <b-form>
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Chương trình đào tạo/Bồi dưỡng"
                  label-for="trainingSystemId"
                >
                  <v-select
                    v-model="filter.trainingSystemId"
                    :options="trainingSystems"
                    :reduce="option => option.value"
                    @input="onTrainingSystemsChange"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Khóa học"
                  label-for="courseId"
                >
                  <v-select
                    v-model="filter.courseId"
                    :options="courses"
                    :reduce="option => option.value"
                    @input="onCoursesChange"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                  label="Lớp cố định"
                  label-for="classId"
                >
                  <v-select
                    v-model="classId"
                    :options="classes"
                    :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  class="text-right"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="my-2"
                  title="Lấy danh sách học viên"
                  @click="onGetListGroup"
                >
                  <feather-icon icon="FilterIcon" /> Danh sách
                </b-button>
                <b-button
                    v-if="classId !== null"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.practicalResearchCreateModal
                    variant="success"
                    class="ml-1 my-2"
                    title="Tạo nhóm cho lớp"
                >
                  <feather-icon icon="CheckIcon" /> Tạo nhóm
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <div class="m-s">
            <b-row class="mt-2">
              <b-col cols="12">
                <div>
                  <vue-good-table
                    mode="remote"
                    class="my-table"
                    style-class="vgt-table striped bordered"
                    row-style-class="vgt-row"
                    :columns="columns"
                    :rows="groupsPracticalResearch"
                    :line-numbers="true"
                    :pagination-options="paginationOptions"
                  >
                    <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                    >
                      Không có bản ghi nào !
                    </div>
                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <span v-if="props.column.field === 'stt'">
                        {{ props.row.originalIndex + 1 }}
                      </span>
                      <span v-else-if="props.column.field === 'timeStart'">
                        {{ formatDate(props.row.timeStart) }}
                      </span>
                      <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                      <span
                        v-if="props.column.field === 'action'"
                        class="thead-group-action"
                      >
                        <b-button-group>
                          <b-dropdown
                            variant="link"
                            no-caret
                          >
                            <template #button-content>
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                              />
                            </template>
                            <b-dropdown-item
                              v-b-modal.practicalResearchStudentListModal
                              class="btn-icon"
                              size="sm"
                              @click="onGetCurrentGroup(props.row)"
                            >
                              <feather-icon icon="UsersIcon" />
                              Danh sách học viên
                            </b-dropdown-item>

                             <b-dropdown-item
                                 v-b-modal.practicalResearchClassGroupTeacher
                                 class="btn-icon"
                                 size="sm"
                                 @click="onGetCurrentGroup(props.row)"
                             >
                              <feather-icon icon="SlackIcon" />
                              Danh sách giảng viên hướng dẫn
                            </b-dropdown-item>

                            <b-dropdown-item
                              v-b-modal.practicalResearchClassGroupSaveModal
                              class="btn-icon"
                              size="sm"
                              @click="onGetCurrentGroup(props.row)"
                            >
                              <feather-icon icon="EditIcon" />
                              Cập nhật thông tin
                            </b-dropdown-item>
                          </b-dropdown>
                        </b-button-group>
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
      <b-modal
        id="practicalResearchCreateModal"
        ref="practicalResearchCreateModal"
        no-close-on-backdrop
        centered
        size="xl"
        :hide-footer="true"
      >
        <PracticalResearchCreate
          :class-id="classId"
          @succeed="onGetListGroup"
        />
      </b-modal>

      <b-modal
        id="practicalResearchStudentListModal"
        ref="practicalResearchStudentListModal"
        no-close-on-backdrop
        centered
        size="xl"
        :hide-footer="true"
      >
        <PracticalResearchStudentList
          :data-list-student="currentListStudent"
          :data-group="currentGroup"
        />
      </b-modal>
      <PracticalResearchClassGroupSave
        ref="practicalResearchClassGroupSaveModal"
        :current-group="currentGroup"
        @succeed="onGetListGroup"
      />
      <b-modal
          id="practicalResearchClassGroupTeacher"
          ref="practicalResearchClassGroupTeacher"
          no-close-on-backdrop
          centered
          size="xl"
          :hide-footer="true"
      >
        <PracticalResearchClassGroupTeacher
            :data-group="currentGroup"
            @succeed="onGetListGroup"
        />
      </b-modal>
    </b-container>
  </div>
</template>
<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem, BForm, BFormGroup,
  BFormSelect, BOverlay, BPagination, BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import PracticalResearchStudentRestAdd from '@/views/practical-research-class-group/PracticalResearchStudentRestAdd.vue'
import PracticalResearchCreate from '@/views/practical-research-class-group/PracticalResearchCreate.vue'
import PracticalResearchStudentList from '@/views/practical-research-class-group/PracticalResearchStudentList.vue'
import PracticalResearchClassGroupSave from '@/views/practical-research-class-group/PracticalResearchClassGroupSave.vue'
import moment from 'moment/moment'
import PracticalResearchClassGroupTeacher
  from "@/views/practical-research-class-group/PracticalResearchClassGroupTeacher.vue";

export default {
  name: 'PracticalResearchClassGroup',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    PracticalResearchClassGroupTeacher,
    PracticalResearchClassGroupSave,
    PracticalResearchStudentList,
    PracticalResearchStudentRestAdd,
    PracticalResearchCreate,
    BButton,
    BDropdown,
    BButtonGroup,
    BDropdownItem,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormGroup,
    BForm,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 3000,
        trainingSystemId: null,
        courseId: null,
      },
      paginationOptions: {
        enabled: false,
      },
      classId: null,
      currentListStudent: [],
      currentGroup: undefined,
      columns: [
        {
          label: 'Tên nhóm',
          field: 'name',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Lớp cố định',
          field: 'class',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Địa điểm nghiên cứu thực tế',
          field: 'address',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Giảng viên hướng dẫn',
          field: 'teachers',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thời gian bắt đầu',
          field: 'timeStart',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thời gian học(ngày)',
          field: 'timeStudy',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      data: [],
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      classes: 'dropdown/classes',
      groupsPracticalResearch: 'practicalResearchClassGroup/groupsPracticalResearch',
    }),
  },
  async created() {
    this.isLoading = true
    try {
      const cUser = getUser()
      this.filter.organizationId = (cUser.orgId ? cUser.orgId : 0)
      await this.getTrainingSystems({ organizationId: this.filter.organizationId })
      if (this.trainingSystems.length > 0) {
        this.filter.trainingSystemId = this.trainingSystems[0].value
        await this.getCourses(this.filter)
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getClasses: 'dropdown/getClasses',
      readGroupsPracticalResearch: 'practicalResearchClassGroup/readGroupsPracticalResearch',
    }),
    async onTrainingSystemsChange(event) {
      this.filter.trainingSystemId = event
      this.filter.courseId = null
      this.classId = null
      await this.getCourses(this.filter)
    },
    async onCoursesChange(event) {
      this.filter.courseId = event
      this.classId = null
      await this.getClasses(this.filter)
    },
    async onGetListGroup() {
      this.isLoading = true
      try {
        await this.readGroupsPracticalResearch({
          classId: this.classId,
          courseId: this.filter.courseId,
          trainingSystemId: this.filter.trainingSystemId,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onGetCurrentGroup(row) {
      this.currentGroup = row
    },
    formatDate(date) {
      return date ? moment(date).format('DD/MM/YYYY') : ''
    },
  },
}
</script>

<style scoped lang="scss">

</style>
