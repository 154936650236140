import { render, staticRenderFns } from "./PracticalResearchTopicStudent.vue?vue&type=template&id=4db9561a&scoped=true&"
import script from "./PracticalResearchTopicStudent.vue?vue&type=script&lang=js&"
export * from "./PracticalResearchTopicStudent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4db9561a",
  null
  
)

export default component.exports