import { render, staticRenderFns } from "./PracticalResearchClassGroup.vue?vue&type=template&id=65d02886&scoped=true&"
import script from "./PracticalResearchClassGroup.vue?vue&type=script&lang=js&"
export * from "./PracticalResearchClassGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65d02886",
  null
  
)

export default component.exports