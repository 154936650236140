<template>
  <div>
    <b-container fluid>
      <b-card>
        <b-overlay>
          <b-row>
            <b-col>
              <h3>
                Tạo nhóm nghiên cứu thực tế của lớp
              </h3>
            </b-col>
          </b-row>
          <validation-observer
            ref="saveFormRef"
            #default="{invalid}"
          >
            <b-row>
              <b-col
                cols="3"
              >
                <b-form-group label-for="numberGroup">
                  <template v-slot:label>
                    Số lượng nhóm<span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Số lượng nhóm"
                    rules="required"
                  >
                    <b-form-input
                      id="numberGroup"
                      v-model="numberGroup"
                      type="number"
                      placeholder="Số lượng nhóm"
                      :state="getElementState(errors)"
                    />
                    <b-form-invalid-feedback :state="getElementState(errors)">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                class="my-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  :disabled="invalid"
                  @click="onCreatePracticalResearch"
                >
                  <span class="text-right">
                    <feather-icon icon="RefreshCwIcon" /> Tạo nhóm
                  </span>
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
          <b-row>
            <b-col>
              <app-collapse>

                <app-collapse-item
                  v-for="(item, index) in dataStudent"
                  :key="index"
                  ref="row"
                  :title="`Danh sách học viên nhóm thứ ${index + 1}`"
                >
                  <b-row>
                    <b-col
                      cols="4"
                    >
                      <b-form>
                        <b-form-group
                          label="Chương trình nghiên cứu thực tế"
                          label-for="practicalResearchProgramId"
                        >
                          <v-select
                            id="practicalResearchProgramId"
                            v-model="targetItem.listGroups[index].group.practicalResearchProgramId"
                            :options="practicalResearchPrograms"
                            :reduce="option => option.value"
                          />
                        </b-form-group>
                      </b-form>
                    </b-col>
                    <b-col
                      cols="3"
                    >
                      <b-form>
                        <b-form-group
                          label="Thời gian bắt đầu"
                          label-for="timeStart"
                        >
                          <b-form-datepicker
                            v-model="targetItem.listGroups[index].group.timeStart"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            locale="vi"
                            format="dd/mm/yyyy"
                            placeholder="dd/mm/yyyy"
                          />
                        </b-form-group>
                      </b-form>
                    </b-col>
                    <b-col
                      cols="4"
                    >
                      <b-form-group
                        label-for="leader"
                      >
                        <template v-slot:label>
                          Trưởng đoàn<span class="text-danger">*</span>
                        </template>
                        <v-select
                          v-model="targetItem.listGroups[index].listTeacher"
                          multiple
                          :options="teachers"
                          :reduce="(option) => option.value"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <vue-good-table
                        :columns="columns"
                        :rows="item"
                        :pagination-options="paginationOptions"
                        :line-numbers="true"
                        class="my-table"
                        style-class="my-table vgt-table striped bordered"
                      >
                        <div
                          slot="emptystate"
                          style="text-align: center; font-weight: bold"
                        >
                          Không có bản ghi nào !
                        </div>
                        <template
                          slot="table-row"
                          slot-scope="props"
                        >
                          <span v-if="props.column.field === 'gender'">
                            {{ getGender(props.row.gender) }}
                          </span>
                          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                        </template>
                      </vue-good-table>
                    </b-col>
                  </b-row>
                </app-collapse-item>
              </app-collapse>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mr-1"
                @click="onSave('hide')"
              >
                <span class="text-right">
                  <feather-icon icon="CheckIcon" /> Lưu lại
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
    </b-container>
  </div>

</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem, BForm, BFormDatepicker, BFormGroup, BFormInput, BFormInvalidFeedback,
  BFormSelect, BModal, BOverlay, BPagination, BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { GENDERS } from '@/const/status'

export default {
  name: 'PracticalResearchCreate',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BContainer,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    BCard,
    ValidationObserver,
    ValidationProvider,
    VueGoodTable,
    AppCollapse,
    AppCollapseItem,
    BFormDatepicker,
  },
  props: {
    classId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
      filter: {
      },
      paginationOptions: {
        enabled: false,
      },
      targetItem: {
        classId: null,
        listGroups: [],
        listStudent: [],
      },
      columns: [
        {
          label: 'Mã học viên',
          field: 'code',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Họ tên',
          field: 'name',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Ngày sinh',
          field: 'birthday',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Lớp học',
          field: 'class',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      dataStudent: [],
      numberGroup: 1,
      minNumberGroup: 1,
      maxNumberGroup: 3,
      minStudentGroup: 1,
    }
  },
  computed: {
    ...mapGetters({
      teachers: 'dropdown/teachers',
      practicalResearchPrograms: 'dropdown/practicalResearchPrograms',
      studentsPracticalResearch: 'practicalResearchClassGroup/studentsPracticalResearch',
    }),
  },
  async created() {
    this.isLoading = true
    try {
      if (this.classId) {
        this.dataStudent = await this.readStudentsPracticalResearch({ classId: this.classId })
      }
      await Promise.all([
        this.getTeachers({ departmentId: null }),
        this.onCreatePracticalResearch(),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getTeachers: 'dropdown/getTeachers',
      createPracticalResearchClassGroup: 'practicalResearchClassGroup/createPracticalResearchClassGroup',
      getPracticalResearchPrograms: 'dropdown/getPracticalResearchPrograms',
      readStudentsPracticalResearch: 'practicalResearchClassGroup/readStudentsPracticalResearch',
    }),
    GENDERS() {
      return GENDERS
    },
    getGender(item) {
      return this.GENDERS().find(gender => gender.value === item).label
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onCreatePracticalResearch() {
      this.isLoading = true
      try {
        await this.getPracticalResearchPrograms({
          currentPage: 1,
          itemsPerPage: 1000,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
      const students = this.studentsPracticalResearch
      const { numberGroup } = this
      const totalStudents = students.length

      const groups = []
      for (let i = 0; i < numberGroup; i++) {
        groups.push([])
      }

      const baseGroupSize = Math.floor(totalStudents / numberGroup)
      const extraStudents = totalStudents % numberGroup

      let studentIndex = 0
      for (let i = 0; i < numberGroup; i++) {
        for (let j = 0; j < baseGroupSize; j++) {
          if (studentIndex < totalStudents) {
            groups[i].push(students[studentIndex])
            studentIndex++
          }
        }
      }

      for (let i = 0; i < extraStudents; i++) {
        if (studentIndex < totalStudents) {
          groups[i].push(students[studentIndex])
          studentIndex++
        }
      }
      // Cập nhật dữ liệu cho listStudent và dataStudent
      this.targetItem.listStudent = groups
      this.dataStudent = this.targetItem.listStudent
      this.targetItem.classId = this.classId
      this.targetItem.listGroups = []
      this.targetItem.listStudent.forEach((item, index) => {
        this.targetItem.listStudent[index].forEach((student, index) => {
          student.status = 0
        })
        this.targetItem.listGroups.push({
          group: {
            address: '',
            name: `Nhóm ${index + 1}`,
            classId: this.classId,
            teacherId: null,
            practicalResearchProgramId: null,
            timeStart: null,
            timeEnd: null,
            status: 1,
          },
          listTeacher: [],
          listStudent: item,
        })
      })
    },
    async onSave(type = null) {
      this.isLoading = true
      try {
        const response = await this.createPracticalResearchClassGroup(this.targetItem)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (type === 'hide') {
              this.$bvModal.hide('practicalResearchCreateModal')
            }
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style scoped lang="scss">

</style>
