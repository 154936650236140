import { render, staticRenderFns } from "./PracticalResearchCreate.vue?vue&type=template&id=154d23f6&scoped=true&"
import script from "./PracticalResearchCreate.vue?vue&type=script&lang=js&"
export * from "./PracticalResearchCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "154d23f6",
  null
  
)

export default component.exports